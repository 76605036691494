import React, { useEffect, useState, useMemo, useCallback } from "react";
import styles from "./index.module.css";
import { useParams } from "react-router-dom";

import RoleSearch from "./RoleSearch/RoleSearch";
import { Auth } from "shared/@auth/Auth";
import Link from "shared/Link";
import LoadingPage from "shared/LoadingPage";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import Badge from "shared/Badge";
import Loader from "shared/Loader";
import { useRolesList } from "Permissions/Roles/useRolesList";

/**
 * User Details.Under users page that has a user details and list of roles.
 */
export default function () {
  const { userId } = useParams();
  const { makeAuthedRequest, hasPermission } = Auth.useContainer();
  const allRoles = useRolesList();
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState(null);
  const [isEditingRoles, setIsEditingRoles] = useState(false);

  const [roleSearchText, setRoleSearchText] = useState("");

  const canSeeRoles = useMemo(
    () => hasPermission("admin.permissions.assignments.read"),
    [hasPermission]
  );
  const canAddRoles = useMemo(
    () => hasPermission("admin.permissions.assignments.create"),
    [hasPermission]
  );
  const canRemoveRoles = useMemo(
    () => hasPermission("admin.permissions.assignments.delete"),
    [hasPermission]
  );

  const selectedIds = useMemo(() => roles && roles.map((r) => r.id), [roles]);

  useEffect(() => {
    makeAuthedRequest(`https://graph.microsoft.com/v1.0/users/${userId}`, {}, [
      "directory.read.all",
    ])
      .then((res) => res.json())
      .then((user) => {
        setUser(user);
        return user;
      })
      .then((user) =>
        makeAuthedRequest(
          `/v1/core/permissions/user/${encodeURIComponent(
            user.userPrincipalName
          )}/roles`
        )
      )
      .then((res) => res.json())
      .then(setRoles)
      .catch((err) => console.error(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addRole = useCallback(
    (roleId) => {
      const role = allRoles.find((r) => r.id === roleId);

      if (!role) {
        throw new Error(`Role with ID ${roleId} does not exist.`);
      }

      const url = `/v1/core/permissions/user/${encodeURIComponent(
        user.userPrincipalName
      )}/role/${role.id}`;

      console.log(user)

      return makeAuthedRequest(url, {
        method: "PUT",
        body: JSON.stringify({
          firstName: user.givenName ? user.givenName : "N/A",
          lastName: user.surname ? user.surname : "N/A",
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setRoles([...(roles || []), role]);
        })
        .catch(console.error);
    },
    [user, roles, allRoles, makeAuthedRequest]
  );

  const removeRole = useCallback(
    (roleId) => {
      const role = roles.find((r) => r.id === roleId);

      if (!role) {
        throw new Error(`Role with ID ${roleId} does not exist.`);
      }

      const url = `/v1/core/permissions/user/${encodeURIComponent(
        user.userPrincipalName
      )}/role/${role.id}`;

      return makeAuthedRequest(url, { method: "DELETE" })
        .then((res) => res.json())
        .then((res) => {
          setRoles((roles || []).filter((r) => r.id !== role.id));
        })
        .catch(console.error);
    },
    [user, roles, makeAuthedRequest]
  );

  if (user == null) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.container}>
      <header>
        <h1>{user.displayName}</h1>
      </header>

      <section>
        {user.jobTitle && (
          <div className={styles.jobTitle}>{user.jobTitle}</div>
        )}
        {user.mail && <span className={styles.email}>{user.mail}</span>}
      </section>

      {canSeeRoles && (
        <section className={styles.roles}>
          <header>
            <h3>
              Roles{" "}
              {roles && (
                <Badge tiny gray>
                  {roles.length}
                </Badge>
              )}
            </h3>

            {(canAddRoles || canRemoveRoles) &&
              (isEditingRoles ? (
                <Button
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEditingRoles(false);
                  }}
                >
                  Done Editing
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEditingRoles(true);
                  }}
                >
                  Edit Roles
                </Button>
              ))}
          </header>

          {roles ? (
            roles.length > 0 ? (
              <ul className={styles.rolesList}>
                {roles.map((role) => (
                  <li key={role.id}>
                    {isEditingRoles && canRemoveRoles && (
                      <Button
                        className={styles.removeRoleButton}
                        purpose={ButtonPurpose.delete}
                        size={ButtonSize.small}
                        onClick={(e) => {
                          e.preventDefault();
                          const msg =
                            "Are you sure? This user will lose these permissions immediately.";

                          if (window.confirm(msg)) {
                            removeRole(role.id);
                          }
                        }}
                      >
                        Remove
                      </Button>
                    )}

                    <div className={styles.roleItemContent}>
                      <Link to={`/permissions-admin/roles/${role.id}`}>
                        {role.name}
                      </Link>
                      <br />
                      <p>{role.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <span>No assigned roles.</span>
            )
          ) : (
            <Loader />
          )}

          {roles && isEditingRoles && canAddRoles && (
            <div className={styles.addRole}>
              <RoleSearch
                value={roleSearchText}
                selectedIds={selectedIds}
                onChange={setRoleSearchText}
              />

              <Button
                onClick={(e) => {
                  e.preventDefault();

                  const role = allRoles.find(
                    (r) => r.name.toLowerCase() === roleSearchText.toLowerCase()
                  );
                  if (!role) {
                    throw new Error(
                      `No role found with name '${roleSearchText}'`
                    );
                  }

                  addRole(role.id);
                }}
              >
                Add Role
              </Button>
            </div>
          )}
        </section>
      )}
    </div>
  );
}
