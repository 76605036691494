import React, { useState, useMemo } from "react";
import styles from "./Users.module.css";
import { useRouteMatch } from "react-router-dom";

import TextInput from "shared/@forms/TextInput";
import Link from "shared/Link";
import Loader from "shared/Loader";
import Card from "shared/Card";

import { useUsersList } from "./useUsersList";

/**
 * User Page. A page that displays users and has a search bar to filter the users.
 */
export default function () {
  const { path } = useRouteMatch();

  const [searchTerm, setSearchTerm] = useState("");
  const list = useUsersList();

  const users = useMemo(() => {
    if (list == null) {
      return null;
    }

    if (searchTerm.trim() === "") {
      return list;
    }

    const term = searchTerm.trim().toLowerCase();

    return list.filter(
      (p) =>
        p.displayName.toLowerCase().includes(term) ||
        (p.jobTitle && p.jobTitle.toLowerCase().includes(term))
    );
  }, [list, searchTerm]);

  return (
    <Card>
      <header className={styles.header}>
        <TextInput
          type="search"
          value={searchTerm}
          placeholder="Search by name"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        {users && (
          <p className={styles.headerUsersLabel}>
            {users.length === list.length ? (
              <span>{users.length} users</span>
            ) : (
              <span>
                Showing {users.length} of {list.length} users
              </span>
            )}
          </p>
        )}
      </header>

      {users === null ? (
        <Loader />
      ) : (
        <ul className={styles.usersList}>
          {users.map((user) => (
            <li key={user.id}>
              <Link
                to={`${path}/${encodeURIComponent(user.userPrincipalName)}`}
              >
                {user.displayName}
              </Link>

              {user.jobTitle && <span> &mdash; {user.jobTitle}</span>}
              <br />
              {user.mail || user.userPrincipalName}
            </li>
          ))}
        </ul>
      )}
    </Card>
  );
}
