import styles from "./index.module.css";
import React from "react";
import classNames from "classnames";
import { ChevronsRight } from "react-ikonate";
import { useLocation, Link } from "react-router-dom";

import { PageNavState } from "./usePageNavState";

export default function Page({ children }) {
  return <div className={styles.page}>{children}</div>;
}

export function Content({ children, className }) {
  return (
    <div id="scrollableDiv" className={classNames(styles.content, className)}>{children}</div>
  );
}

export function Nav({ className, children }) {
  const { collapsed, setCollapsed } = PageNavState.useContainer();

  return (
    <nav
      className={classNames(styles.nav, className, {
        [styles.collapsed]: collapsed,
      })}
    >
      <ul className={styles.list}>{children}</ul>

      <button
        className={styles.collapseToggle}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <ChevronsRight className={styles.collapseIcon} />

        <span className={styles.collapseLabel}>Collapse</span>
      </button>
    </nav>
  );
}

export function NavGroup({ title, children }) {
  return (
    <li className={styles.group}>
      <header>
        <span>{title}</span>
      </header>
      <ul className={styles.groupList}>{children}</ul>
    </li>
  );
}

export function NavItem({ icon, to, children, onClick }) {
  const location = useLocation();
  const { collapsed } = PageNavState.useContainer();

  const isActive = location.pathname.startsWith(to);
  const title = typeof children === "string" ? children : "nav icon";

  return (
    <li
      className={classNames(styles.item, {
        [styles.active]: isActive,
        [styles.collapsed]: collapsed,
      })}
      key={to}
      onClick={onClick}
    >
      <Link className={styles.itemLink} to={to} title={title}>
        {icon && <div className={styles.itemIcon}>{icon}</div>}
        {!collapsed && <span>{children}</span>}
      </Link>
    </li>
  );
}
