import React, { useState, useMemo } from "react";
import styles from "./index.module.css";

import Loader from "../../shared/Loader";

import { usePermissionsList } from "./usePermissionsList";
import TextInput from "shared/@forms/TextInput";

/**
 * All Permision. List of permissions with a search bar to filter permissions.
 */
export default function () {
  const [searchTerm, setSearchTerm] = useState("");
  const list = usePermissionsList();

  const permissions = useMemo(() => {
    if (list == null) {
      return null;
    }

    if (searchTerm.trim() === "") {
      return list.sort((a, b) => (a.key < b.key ? -1 : 1));
    }

    const term = searchTerm.trim().toLowerCase();

    return list
      .filter(
        (p) =>
          p.key.includes(term) || p.description.toLowerCase().includes(term)
      )
      .sort((a, b) => (a.key < b.key ? -1 : 1));
  }, [list, searchTerm]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <TextInput
          placeholder="Search permissions"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />

        {permissions && (
          <p className={styles.searchCount}>
            {permissions.length === list.length ? (
              <span>{permissions.length} permissions</span>
            ) : (
              <span>
                Showing {permissions.length} of {list.length} permissions
              </span>
            )}
          </p>
        )}
      </header>

      {permissions == null ? (
        <Loader />
      ) : (
        <ul className={styles.list}>
          {permissions.map(({ key, description }) => (
            <li className={styles.listItem} key={key}>
              <code className={styles.itemName}>{key}</code>
              <span className={styles.itemDescription}>{description}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
