import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";
import { Auth } from "shared/@auth/Auth";

/**
 * Fetches a list of all users in the AD Tenant.
 */
export function useUsersList() {
  const { makeAuthedRequest } = Auth.useContainer();
  const [list, setList] = useState(null);

  useEffect(() => {
    let temp = [];

    const done = (res) => {
      temp.push(...res.value);

      if (res["@odata.nextLink"]) {
        makeAuthedRequest(res["@odata.nextLink"], {}, ["directory.read.all"])
          .then((res) => res.json())
          .then(done)
          .catch((err) => console.error(err));
      } else {
        const filteredList = temp.filter((user) => user.surname);
        setList(filteredList);
      }
    };

    makeAuthedRequest("https://graph.microsoft.com/v1.0/users", {}, [
      "directory.read.all",
    ])
      .then((res) => res.json())
      .then(done)
      .catch((err) => console.error(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return list;
}

export const UsersList = createContainer(useUsersList);
