import React, { useEffect, useState, useMemo, useCallback } from "react";
import styles from "./RoleDetails.module.css";
import { useHistory, useParams } from "react-router-dom";

import { Auth } from "shared/@auth/Auth";
import Link from "shared/Link";
import LoadingPage from "shared/LoadingPage";
import Loader from "shared/Loader";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import EditableText from "shared/@forms/EditableText";
import Badge from "shared/Badge";

import UserSearch from "./UserSearch/UserSearch";
import PermissionSearch from "./PermissionSearch";
import { useUsersList } from "Permissions/Users/useUsersList";

/**
 * Role Details. A details of role which you can add/edit users and permissions.
 */
export default function () {
  const history = useHistory();
  const { roleId } = useParams();
  const usersList = useUsersList();
  const { makeAuthedRequest, hasPermission } = Auth.useContainer();

  // Determine the user's permissions.
  const canSeeUsers = useMemo(
    () => hasPermission("admin.permissions.assignments.read"),
    [hasPermission]
  );
  const canAddUser = useMemo(
    () => hasPermission("admin.permissions.assignments.create"),
    [hasPermission]
  );
  const canRemoveUser = useMemo(
    () => hasPermission("admin.permissions.assignments.delete"),
    [hasPermission]
  );
  const canEditRole = useMemo(
    () => hasPermission("admin.permissions.roles.update"),
    [hasPermission]
  );

  const [role, setRole] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [permissions, setPermissions] = useState(null);
  const [editingPermissions, setEditingPermissions] = useState(false);
  const [addPermissionValue, setAddPermissionValue] = useState("");

  const [users, setUsers] = useState(null);
  const [editingUsers, setEditingUsers] = useState(false);
  const [addUserValue, setAddUserValue] = useState({});
  const [addUserSearch, setAddUserSearch] = useState("");

  useEffect(() => {
    if (addUserSearch && usersList && usersList.length > 0) {
      let u = usersList.find(
        (a) => a.userPrincipalName.toLowerCase() == addUserSearch.toLowerCase()
      );
      if (u) {
        setAddUserValue(u);
      }
    }
  }, [usersList, addUserSearch]);

  useEffect(() => {
    makeAuthedRequest(`/v1/core/permissions/roles/role/${roleId}`)
      .then((res) => res.json())
      .then((role) => {
        setRole(role);
        setName(role.name);
        setDescription(role.description);
      })
      .catch(console.error);

    makeAuthedRequest(`/v1/core/permissions/roles/${roleId}/permissions`)
      .then((res) => res.json())
      .then((value) => {
        setPermissions(value.map((p) => p.key));
      })
      .catch(console.error);

    makeAuthedRequest(`/v1/core/permissions/roles/${roleId}/users`)
      .then((res) => res.json())
      .then(setUsers)
      .catch(console.error);
  }, [makeAuthedRequest, roleId]);

  const deleteRole = useCallback(() => {
    if (role) {
      return makeAuthedRequest(`/v1/core/permissions/roles/${role.id}`, {
        method: "DELETE",
      });
    } else {
      console.warn("Attempted to delete role before it was loaded.");
    }
  }, [makeAuthedRequest, role]);

  const updateRole = useCallback(
    (data) => {
      if (roleId) {
        return makeAuthedRequest(`/v1/core/permissions/roles/${roleId}`, {
          method: "PUT",
          body: JSON.stringify(data),
        });
      } else {
        console.warn("Attempted to update role before it was loaded.");
      }
    },
    [makeAuthedRequest, roleId]
  );

  const updateName = useCallback(
    (name) => {
      setName(name);
      return updateRole({
        name: name,
        description: description,
        permissions: permissions,
      });
    },
    [updateRole, description, permissions]
  );

  const updateDescription = useCallback(
    (description) => {
      setDescription(description);
      return updateRole({
        name: name,
        description: description,
        permissions: permissions,
      });
    },
    [updateRole, description, permissions]
  );

  const addPermission = useCallback(
    function (key) {
      const perm = [...permissions, key];

      setPermissions(perm);
      return updateRole({
        name: name,
        description: description,
        permissions: perm,
      });
    },
    [updateRole, permissions, name, description]
  );

  const removePermission = useCallback(
    function (key) {
      const perm = permissions.filter((p) => p !== key);

      setPermissions(perm);
      return updateRole({
        name: name,
        description: description,
        permissions: perm,
      });
    },
    [updateRole, permissions, name, description]
  );

  const addUser = useCallback(
    (username, firstName, lastName) => {
      const url = `/v1/core/permissions/user/${encodeURIComponent(
        username
      )}/role/${role.id}`;

      const data = {
        firstName: firstName ? firstName : "N/A",
        lastName: lastName ? lastName : "N/A",
      };

      return makeAuthedRequest(url, {
        method: "PUT",
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setUsers([...(users || []), username]);
        })
        .catch(console.error);
    },
    [makeAuthedRequest, role, users]
  );

  const removeUser = useCallback(
    (username) => {
      const url = `/v1/core/permissions/user/${encodeURIComponent(
        username
      )}/role/${role.id}`;

      return makeAuthedRequest(url, { method: "DELETE" })
        .then((res) => res.json())
        .then((res) => {
          setUsers((users || []).filter((u) => u !== username));
        })
        .catch(console.error);
    },
    [makeAuthedRequest, role, users]
  );

  if (role == null) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <EditableText
          value={name}
          onChange={updateName}
          // inputClassName={styles.nameInput}
        >
          {(value) => <h1>{value}</h1>}
        </EditableText>
        <EditableText value={description} onChange={updateDescription}>
          {(value) => <p>{value}</p>}
        </EditableText>
      </header>

      {canSeeUsers && (
        <section>
          <header>
            <h3>
              Users
              {users && (
                <Badge tiny gray>
                  {users.length}
                </Badge>
              )}
            </h3>

            {(canAddUser || canRemoveUser) &&
              (editingUsers ? (
                <Button
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditingUsers(false);
                  }}
                >
                  Done Editing
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditingUsers(true);
                  }}
                >
                  Edit Users
                </Button>
              ))}
          </header>

          {users == null ? (
            <Loader />
          ) : (
            <>
              <ul className={styles.usersList}>
                {users.map((username) => (
                  <li key={username}>
                    {editingUsers && canRemoveUser && (
                      <Button
                        purpose={ButtonPurpose.delete}
                        size={ButtonSize.small}
                        className={styles.removeButton}
                        onClick={(e) => {
                          e.preventDefault();
                          const msg =
                            "Really remove this user? They will lose this role's permissions immediately.";

                          if (window.confirm(msg)) {
                            removeUser(username);
                          }
                        }}
                      >
                        Remove
                      </Button>
                    )}

                    <Link
                      to={`/permissions-admin/users/${encodeURIComponent(
                        username
                      )}`}
                    >
                      {username}
                    </Link>
                  </li>
                ))}
              </ul>

              {editingUsers && canAddUser && (
                <div className={styles.addUser}>
                  <UserSearch
                    value={addUserSearch}
                    selectedUsers={users}
                    onChange={setAddUserSearch}
                  />

                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      addUser(
                        addUserValue.userPrincipalName,
                        addUserValue.givenName,
                        addUserValue.surname
                      ).then(() => {
                        setAddUserSearch("");
                      });
                    }}
                  >
                    Add User
                  </Button>
                </div>
              )}
            </>
          )}
        </section>
      )}

      <section>
        <header>
          <h3>
            Permissions{" "}
            {permissions && (
              <Badge tiny gray>
                {permissions.length}
              </Badge>
            )}
          </h3>
          {canEditRole &&
            (editingPermissions ? (
              <Button
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  setEditingPermissions(false);
                }}
              >
                Done Editing
              </Button>
            ) : (
              <Button
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  setEditingPermissions(true);
                }}
              >
                Edit Permissions
              </Button>
            ))}
        </header>
        {permissions == null ? (
          <Loader />
        ) : (
          <ul className={styles.permissionsList}>
            {permissions.map((key) => (
              <li key={key}>
                {editingPermissions && (
                  <Button
                    size={ButtonSize.small}
                    purpose={ButtonPurpose.delete}
                    className={styles.removeButton}
                    onClick={(e) => {
                      e.preventDefault();
                      const msg =
                        "Really remove this permission? All users assigned to this role will lose this permission immediately.";

                      if (window.confirm(msg)) {
                        removePermission(key);
                      }
                    }}
                  >
                    Remove
                  </Button>
                )}

                <code>{key}</code>
              </li>
            ))}
          </ul>
        )}

        {editingPermissions && (
          <div className={styles.addPermission}>
            <PermissionSearch
              value={addPermissionValue}
              selectedKeys={permissions}
              onChange={setAddPermissionValue}
            />

            <Button
              disabled={addPermissionValue.length < 1}
              onClick={(e) => {
                e.preventDefault();
                addPermission(addPermissionValue).then(() => {
                  setAddPermissionValue("");
                });
              }}
            >
              Add Permission
            </Button>
          </div>
        )}
      </section>

      {hasPermission("admin.permissions.roles.delete") && (
        <section>
          <header>
            <h3>Danger Zone</h3>
          </header>
          <Button
            purpose={ButtonPurpose.delete}
            onClick={(e) => {
              e.preventDefault();
              let msg =
                "Really delete role? Everyone assigned this role will immediately lose these permissions.";

              if (window.confirm(msg)) {
                deleteRole()
                  .then(() => {
                    history.push("/permissions-admin/roles");
                  })
                  .catch(console.error);
              }
            }}
          >
            Delete Role
          </Button>
        </section>
      )}
    </div>
  );
}
