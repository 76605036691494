import styles from "./index.module.css";
import React, { useCallback, useState } from "react";
import { usePermissionsList } from "Permissions/List/usePermissionsList";

import SuggestionInput from "shared/@forms/SuggestionInput";

/**
 * Permission Search. A search bar that filters and add permissions.
 */
export default function ({ value, onChange, selectedKeys = [] }) {
  const permissions = usePermissionsList();
  const [suggestions, setSuggestions] = useState([]);

  const update = useCallback(
    (newVal) => {
      if (onChange) onChange(newVal);
    },
    [onChange]
  );

  const fetchSuggestions = useCallback(async () => {
    const term = value.toLowerCase();

    if (permissions == null || term.trim() === "") return [];

    setSuggestions(
      permissions.filter(
        (u) => u.key.includes(term) && !selectedKeys.includes(u.key)
      )
    );
  }, [permissions, value, selectedKeys]);

  return (
    <SuggestionInput
      value={value}
      fetchSuggestions={fetchSuggestions}
      suggestions={suggestions}
      onChange={(e) => {
        update(e.target.value);
      }}
    >
      {(permission) => (
        <button
          key={permission.id}
          className={styles.listItem}
          onClick={(e) => {
            e.preventDefault();
            update(permission.key);
          }}
        >
          <p className={styles.listItemKey}>
            <code>{permission.key}</code>
          </p>
          <p className={styles.listItemDescription}>{permission.description}</p>
        </button>
      )}
    </SuggestionInput>
  );
}
