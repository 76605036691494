import styles from "./UserSearch.module.css";
import React, { useCallback, useState } from "react";
import { useUsersList } from "Permissions/Users/useUsersList";

import SuggestionInput from "shared/@forms/SuggestionInput";

/**
 * User Search. Under roles page with a search bar to filter user and add/remove user role.
 */
export default function ({ value, onChange, selectedUsers = [] }) {
  const users = useUsersList();
  const [suggestions, setSuggestions] = useState([]);

  const update = useCallback(
    (newVal) => {
      if (onChange) onChange(newVal);
    },
    [onChange]
  );

  const fetchSuggestions = useCallback(async () => {
    const term = value.toLowerCase();

    if (users == null || term.trim() === "") return [];

    setSuggestions(
      users.filter(
        (u) =>
          (u.displayName + " " + u.userPrincipalName)
            .toLowerCase()
            .includes(term) && !selectedUsers.includes(u.userPrincipalName)
      )
    );
  }, [users, value, selectedUsers]);

  return (
    <SuggestionInput
      value={value}
      fetchSuggestions={fetchSuggestions}
      suggestions={suggestions}
      onChange={(e) => {
        update(e.target.value);
      }}
    >
      {(user) => (
        <button
          key={user.id}
          className={styles.listItem}
          onClick={(e) => {
            e.preventDefault();
            update(user.userPrincipalName);
          }}
        >
          <div>
            <span>User: </span>
            <span>
              <strong>{user.displayName}</strong>
            </span>
            <span> ({user.userPrincipalName})</span>
          </div>
          <div>
            <span>Select</span>
          </div>
        </button>
      )}
    </SuggestionInput>
  );
}
