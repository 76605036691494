import { useState, useEffect } from "react";
import { Auth } from "shared/@auth/Auth";
import { ascendingBy } from "shared/@utils/sort";

export function useRolesList() {
  const { makeAuthedRequest } = Auth.useContainer();
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    makeAuthedRequest(`/v1/core/permissions/roles/list`)
      .then((res) => res.json())
      .then((list) => list.sort(ascendingBy("name")))
      .then(setRoles)
      .catch(console.error);
  }, [makeAuthedRequest]);

  return roles;
}
