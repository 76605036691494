import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Lock as LockIcon, PersonAdd as PersonAddIcon } from "react-ikonate";
import Page, { Content, Nav, NavItem, NavGroup } from "shared/Page";

import Signup from "./Signup";

export default function ErrorCodes() {
  const { path } = useRouteMatch();

  return (
    <Page>
      {/* <Nav>
        <NavGroup title="Apps">
          <NavItem to={`${path}/signup`} icon={<PersonAddIcon />}>
            Membership Signup
          </NavItem>
        </NavGroup>
      </Nav> */}
      <Content>
        <Switch>
          <Route path={`${path}/signup`}>
            <Signup />
          </Route>

          <Route path="*">
            <Redirect to={`${path}/signup`} />
          </Route>
        </Switch>
      </Content>
    </Page>
  );
}
