import styles from "./index.module.css";
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * Displays a Badge to bring attention to the user. Tiny, small, gray are all optional ways to change the style of the Badge.
 */

export default function Badge({ children, tiny, small, gray }) {
  return (
    <span
      className={classNames(styles.badge, {
        [styles.tiny]: tiny,
        [styles.small]: small,
        [styles.gray]: gray,
      })}
    >
      {children}
    </span>
  );
}

Badge.propTypes = {
  /**
   * These are all optional props to change the style of the Badge.
   */
  tiny: PropTypes.bool,
  small: PropTypes.bool,
  gray: PropTypes.bool,
};
