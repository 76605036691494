import styles from "./index.module.css";
import React from "react";
import { useRouteMatch } from "react-router-dom";

import { useRolesList } from "./useRolesList";

import Card from "shared/Card";
import Link from "shared/Link";
import { Auth } from "shared/@auth/Auth";
import LoadingPage from "shared/LoadingPage";
import { ButtonLink } from "shared/Button";

/**
 * Roles. A page that displays roles(list of permissions) which descriptions in sorted alphabetically.
 */
export default function () {
  const { path } = useRouteMatch();
  const { hasPermission } = Auth.useContainer();
  const roles = useRolesList();

  if (roles == null) {
    return <LoadingPage />;
  }

  return (
    <div>
      <p>
        Roles are lists of permissions with users attached. Permissions can be
        grouped based on department, job title, etc., then each user can be
        added to any number of roles as needed.
      </p>
      <br />

      <Card>
        <ul className={styles.rolesList}>
          {roles.map(({ id, name, description }) => (
            <li key={id}>
              <Link to={`${path}/${id}`}>{name}</Link>
              <br />
              {description}
            </li>
          ))}
        </ul>
      </Card>

      {hasPermission("admin.permissions.roles.create") && (
        <div className={styles.roleCreate}>
          <ButtonLink to={`/permissions-admin/roles/create`}>
            Add Role
          </ButtonLink>
        </div>
      )}
    </div>
  );
}
