import Table from "shared/Table";
import Card from "shared/Card";

export default function Signup() {
  return (
    <Card>
      <Table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Z407</td>
            <td>
              Default error code. Shows if something we didn't foresee was the
              cause of the crash.
            </td>
          </tr>
          <tr>
            <td>M001</td>
            <td>Crashed while inserting IBO A contact information.</td>
          </tr>
          <tr>
            <td>M002</td>
            <td>Crashed while saving credit card data.</td>
          </tr>
          <tr>
            <td>M003</td>
            <td>
              Crashed while processing payment and setting up web account data.
            </td>
          </tr>
          <tr>
            <td>M004</td>
            <td>Crashed while inserting community records.</td>
          </tr>
          <tr>
            <td>M005</td>
            <td>Crashed while inserting IBO B contact information.</td>
          </tr>
          <tr>
            <td>M006</td>
            <td>Crashed while redirecting to the receipt page.</td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
