import styles from "./RoleSearch.module.css";
import React, { useCallback, useState } from "react";
import { useRolesList } from "Permissions/Roles/useRolesList";

import SuggestionInput from "shared/@forms/SuggestionInput";

/**
 * Role Search. Under users page with a search bar to filter roles and add new role to the user.
 */
export default function ({ value, onChange, selectedIds = [] }) {
  const roles = useRolesList();
  const [suggestions, setSuggestions] = useState([]);

  const update = useCallback(
    (newVal) => {
      if (onChange) onChange(newVal);
    },
    [onChange]
  );

  const fetchSuggestions = useCallback(() => {
    const term = value.toLowerCase();

    if (roles == null || term.trim() === "") return [];

    setSuggestions(
      roles.filter(
        (r) =>
          r.name.toLowerCase().includes(term) && !selectedIds.includes(r.id)
      )
    );
  }, [value, roles, selectedIds]);

  return (
    <SuggestionInput
      value={value}
      fetchSuggestions={fetchSuggestions}
      suggestions={suggestions}
      onChange={(e) => {
        update(e.target.value);
      }}
    >
      {(role) => (
        <button
          key={role.id}
          className={styles.listItem}
          onClick={(e) => {
            e.preventDefault();
            update(role.name);
          }}
        >
          <div>
            <span>Role: </span>
            <span className={styles.roleName}>{role.name}</span>
            <span> ({role.description})</span>
          </div>
          <div>
            <span>Add</span>
          </div>
        </button>
      )}
    </SuggestionInput>
  );
}
