import React, { useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import { Lock as LockIcon } from "react-ikonate";
import { IconAlert } from "@wwg/icons";
import Page, { Content, Nav, NavItem, NavGroup } from "shared/Page";

import Token from "./Token";
import ErrorCodes from "../ErrorCodes";

function Developer() {
  const { path } = useRouteMatch();
  const { setCurrentRoute } = useCurrentRoute();

  useEffect(() => {
    setCurrentRoute("/developer");
    return () => {
      setCurrentRoute("");
    };
  });

  return (
    <Page>
      <Nav>
        <NavGroup title="Utils">
          <NavItem to={`${path}/token`} icon={<LockIcon />}>
            API Access Token
          </NavItem>
          <NavItem to={`${path}/codes`} icon={<IconAlert />}>
            Error Codes
          </NavItem>
        </NavGroup>
      </Nav>
      <Content>
        <Switch>
          <Route path={`${path}/token`}>
            <Token />
          </Route>

          <Route path={`${path}/codes`}>
            <ErrorCodes />
          </Route>

          <Route path="*">
            <Redirect to={`${path}/token`} />
          </Route>
        </Switch>
      </Content>
    </Page>
  );
}

export default Developer;
