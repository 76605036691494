import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import Page, { Content, Nav, NavItem, NavGroup } from "../shared/Page";
import {
  List as ListIcon,
  Key as KeyIcon,
  Person as PersonIcon,
} from "react-ikonate";

import { Auth } from "shared/@auth/Auth";
import List from "./List";
import RoleCreate from "./RoleCreate";
import RoleDetails from "./RoleDetails/RoleDetails";
import Roles from "./Roles";
import UserDetails from "./UserDetails";
import Users from "./Users/Users";

export default function IBOLogin() {
  const { path } = useRouteMatch();
  const { hasPermission } = Auth.useContainer();

  return (
    <Page>
      <Nav appName="Permissions Admin">
        <NavGroup title="Permissions Admin">
          {hasPermission("admin.permissions.list.read") && (
            <NavItem to={`${path}/list`} icon={<ListIcon />}>
              All Permissions
            </NavItem>
          )}
          {hasPermission("admin.permissions.roles.read") && (
            <NavItem to={`${path}/roles`} icon={<KeyIcon />}>
              Roles
            </NavItem>
          )}
          {hasPermission("admin.users.read") && (
            <NavItem to={`${path}/users`} icon={<PersonIcon />}>
              Users
            </NavItem>
          )}
        </NavGroup>
      </Nav>

      <Content>
        <Switch>
          {hasPermission("admin.users.read") && (
            <Route exact path={`${path}/users/:userId`}>
              <UserDetails />
            </Route>
          )}
          {hasPermission("admin.users.read") && (
            <Route exact path={`${path}/users`}>
              <Users />
            </Route>
          )}
          {hasPermission("admin.permissions.roles.create") && (
            <Route exact path={`${path}/roles/create`}>
              <RoleCreate />
            </Route>
          )}
          {hasPermission("admin.permissions.roles.read") && (
            <Route exact path={`${path}/roles/:roleId`}>
              <RoleDetails />
            </Route>
          )}
          {hasPermission("admin.permissions.roles.read") && (
            <Route exact path={`${path}/roles`}>
              <Roles />
            </Route>
          )}
          {hasPermission("admin.permissions.list.read") && (
            <Route path={`${path}/list`}>
              <List />
            </Route>
          )}

          <Route path="*">
            <Redirect to={`${path}/list`} />
          </Route>
        </Switch>
      </Content>
    </Page>
  );
}
