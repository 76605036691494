import styles from "./index.module.css";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Edit } from "react-ikonate";
import PropTypes from "prop-types";

import TextInput from "shared/@forms/TextInput";
import Button from "shared/Button";

/**
 * A basic text field with inline editing abilities.
 */
export default function EditableText({
  value = "",
  children,
  onChange,
  onCancel,
  submitText = "Submit",
  cancelText = "Cancel",
  inputClassName,
}) {
  if (typeof onChange !== "function") {
    throw new Error("onChange must be a function");
  }

  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  // Update editValue if value prop changes.
  useEffect(() => {
    setEditValue(value);
  }, [value]);

  if (isEditing) {
    return (
      <form
        className={styles.row}
        onSubmit={(e) => {
          e.preventDefault();

          onChange(editValue);
          setIsEditing(false);
        }}
      >
        <TextInput
          autoFocus={true}
          value={editValue}
          className={classNames(styles.input, inputClassName)}
          onFocus={(e) => {
            e.target.select();
          }}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              if (typeof onCancel === "function") {
                onCancel();
              }
              setIsEditing(false);
            }
          }}
          onChange={(e) => {
            setEditValue(e.target.value);
          }}
        />
        <Button type="submit">{submitText}</Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (typeof onCancel === "function") {
              onCancel();
            }
            setIsEditing(false);
          }}
        >
          {cancelText}
        </Button>
      </form>
    );
  } else if (typeof children === "function") {
    // Use a function to render as custom component.
    return (
      <div className={styles.row}>
        <div>{children(value)}</div>
        <button
          className={styles.editButton}
          onClick={(e) => {
            e.preventDefault();
            setIsEditing(true);
          }}
        >
          <Edit />
        </button>
      </div>
    );
  } else {
    return (
      <div className={styles.row}>
        <span>{value}</span>
        <button
          className={styles.editButton}
          onClick={(e) => {
            e.preventDefault();
            setIsEditing(true);
          }}
        >
          <Edit />
        </button>
      </div>
    );
  }
}

EditableText.propTypes = {
  /**
   * The initial, editable string.
   */
  value: PropTypes.string,

  /**
   * Runs when a new value is submitted. Receives the new value.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Runs when editing is cancelled.
   */
  onCancel: PropTypes.func,

  /**
   * Text to display on 'submit' button when editing.
   */
  submitText: PropTypes.string,

  /**
   * Text to display on 'cancel' button when editing.
   */
  cancelText: PropTypes.string,

  /**
   * Optional className for input element.
   */
  inputClassName: PropTypes.string,
};
