import styles from "./index.module.css";
import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "shared/@auth/Auth";
import Button from "shared/Button";
import TextInput from "shared/@forms/TextInput";
import TextArea from "shared/@forms/TextArea";
import { useLogger } from "shared/@hooks/useLogger";

/**
 * Role Create. Under roles page a form to create/add new role (name and description).
 */
export default function () {
  const { makeAuthedRequest } = Auth.useContainer();
  const { logError } = useLogger();
  const history = useHistory();

  const [error, setError] = useState(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const submit = useCallback(
    (e) => {
      e.preventDefault();

      setError(null);

      makeAuthedRequest(`/v1/core/permissions/roles`, {
        method: "POST",
        body: JSON.stringify({
          name,
          description,
          permissions: [],
        }),
      })
        .then(async (res) => {
          if (!res.ok) {
            const body = await res.json();
            throw new Error(body.message || "BaAaaAAaaaahHHhh");
          }

          return res;
        })
        .then((res) => res.json())
        .then((res) => {
          const { id } = res;
          history.push(`/permissions-admin/roles/${id}`);
        })
        .catch((err) => {
          setError(err.message);
          logError(err);
        });
    },
    [name, description, history, makeAuthedRequest, logError]
  );

  return (
    <form className={styles.container} onSubmit={submit}>
      {error && <p className={styles.error}>{error}</p>}

      <div className={styles.formGroup}>
        <label htmlFor="name">Name</label>
        <TextInput
          id="name"
          placeholder="New Role"
          value={name}
          onChange={(e) => void setName(e.target.value)}
          autoFocus={true}
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="description">Description</label>
        <TextArea
          id="description"
          placeholder="Describe who this role is for and what it's about."
          value={description}
          onChange={(e) => void setDescription(e.target.value)}
          required
        />
      </div>

      <Button>Create Role</Button>
    </form>
  );
}
