import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";
import { Auth } from "shared/@auth/Auth";

export function usePermissionsList() {
  const { makeAuthedRequest } = Auth.useContainer();
  const [list, setList] = useState(null);

  useEffect(() => {
    makeAuthedRequest("/v1/core/permissions/list")
      .then((res) => res.json())
      .then(setList)
      .catch((err) => console.error(err));
  }, [makeAuthedRequest]);

  return list;
}

export const PermissionsList = createContainer(usePermissionsList);
