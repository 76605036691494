import styles from "./index.module.css";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import classNames from "classnames";
import { useClickAway } from "react-use";

import TextInput from "shared/@forms/TextInput";
import Loader from "shared/Loader";

/**
 * A 'timeout container' that calls a function after the timeout has elapsed.
 * Subsequent calls will cancel the previous function and queue up the new one.
 *
 * @param {number} timeout - Milliseconds to wait before calling the function.
 */
function Debouncer(timeout = 300) {
  let pending = null;

  return {
    call(fn) {
      if (pending) {
        window.clearTimeout(pending);
      }

      pending = window.setTimeout(() => {
        fn();
        window.clearTimeout(pending);
      }, timeout);
    },
    cancel() {
      if (pending) {
        window.clearTimeout(pending);
        pending = null;
      }
    },
  };
}

/**
 * A text input that displays suggestions as you type.
 *
 * Takes a function as a child that renders each item in the suggestions list.
 *
 * `fetchSuggestions` is a function that kicks off the search process. It takes the search term as an argument.
 * `suggestions` is the array of search results.
 * `isLoading` determines whether to show the loader.
 * How this loading happens is up to the component using SuggestionInput.
 */
export default function SuggestionInput({
  value,

  fetchSuggestions,
  suggestions,
  isLoading,

  minLength = 0,
  debounceTime = 200,
  children,
  ...props
}) {
  const renderChild = children;

  // const { logError } = useLogger({ prefix: "SuggestionInput" });
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [lastValue, setLastValue] = useState(null);

  const debouncer = useMemo(() => Debouncer(debounceTime), [debounceTime]);

  const showSuggestions = isInputFocused;

  const container = useRef();
  useClickAway(container, (e) => {
    setIsInputFocused(false);
  });

  useEffect(() => {
    if (value !== lastValue) {
      setLastValue(value);

      debouncer.call(() => {
        setLastValue(value);
        fetchSuggestions(value);
      });
    }
  }, [value, lastValue, debouncer, fetchSuggestions]);

  // Callback for child items to unfocus the input and hide the suggestions panel.
  const closePanel = useCallback(() => {
    container.current.blur();
    setIsInputFocused(false);
  }, [container]);

  let statusLabel;

  if (isLoading) {
    statusLabel = "Getting results...";
  } else if (value.length === 0) {
    statusLabel = "Start typing to search";
  } else if (minLength && value.length < minLength) {
    let x = minLength - value.length;
    statusLabel = `${x} more character${x === 1 ? "" : "s"}`;
  } else if (suggestions.length > 0) {
    let x = suggestions.length;
    statusLabel = `${x} result${x === 1 ? "" : "s"}`;
  } else {
    statusLabel = "No results found";
  }

  return (
    <div ref={container} className={styles.container}>
      <div
        className={classNames(styles.suggestionPanel, {
          [styles.open]: showSuggestions,
        })}
      >
        <div className={styles.statusLabel}>
          {isLoading && (
            <div className={styles.statusLoader}>
              <Loader size={32} />
            </div>
          )}
          {statusLabel}
        </div>
        <div className={styles.suggestions}>
          {suggestions.map((item) => renderChild(item, closePanel))}
        </div>
      </div>

      <TextInput
        className={styles.input}
        value={value}
        {...props}
        onFocus={(e) => {
          setIsInputFocused(true);

          if (props.onFocus) {
            props.onFocus(e);
          }
        }}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e);
          }
        }}
      />
    </div>
  );
}
