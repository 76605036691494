import styles from "./index.module.css";
import React, { useCallback } from "react";
import { Auth } from "shared/@auth/Auth";
import Button from "shared/Button";
import { useLogger } from "shared/@hooks/useLogger";
import createNotification from "shared/@utils/createNotification";

export default function () {
  const { logError } = useLogger({ prefix: "Developer" });
  const { getToken } = Auth.useContainer();

  const retrieveToken = useCallback(() => {
    getToken()
      .then(async (token) => {
        return navigator.clipboard.writeText(token).then(() => {
          createNotification({
            type: "success",
            title: "Copied",
            message: "Access token was copied to your clipboard.",
            autoDismiss: true,
          });
        });
      })
      .catch(logError);
  }, [getToken, logError]);

  return (
    <div className={styles.container}>
      <p className={styles.notice}>
        Tokens are required for making test calls to the API through
        Postman/Insomnia/etc. You can always copy your latest auth token here.
      </p>

      <Button onClick={retrieveToken}>Copy Token to Clipboard</Button>
    </div>
  );
}
